import styled from 'styled-components';

export const HeaderH2 = styled.h2`
  text-align: center;
  position: relative;
  margin: 0 0 35px 0;
  font-family: 'MrAlex', sans-serif;
  font-size: 45px;
  line-height: 50px;
  color: #00539d;
  @media (max-width: 1399px) {
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 30px;
    line-height: 35px;
  }
`;
export const HeaderH3 = styled.h3`
  margin: 0 0 35px 0;
  font-family: 'HelveticaNeue-Light', sans-serif;
  font-size: 32px;
  line-height: 37px;
  color: #00a6e8;
  text-align: center;
  @media (max-width: 1399px) {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 25px;
    line-height: 30px;
  }
`;
export const ParaText = styled.p`
  margin-bottom: 40px;
  @media (max-width: 1399px) {
    margin-bottom: 30px;
  }
  i {
    font-style: italic;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 24px;
    @media (max-width: 1399px) {
      font-size: 15px;
      line-height: 22px;
    }
    @media (max-width: 767px) {
      font-size: 13px;
      line-height: 20px;
    }
  }
`;

export const LinkStyle = styled.a`
  text-decoration: none;
  color: #00a6e8;
  transition: 0.25s ease-out;
`;
export const ImageStyle = styled.img`
  border: 0;
  vertical-align: top;
  width: 100px;
  height: 100px;
`;
